import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../imgs/logo.png';
import { CiCloudSun } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { DiCodeigniter } from "react-icons/di";
import { ImBlog } from "react-icons/im";
import { RiCodeSSlashLine } from "react-icons/ri";
import { FaProjectDiagram } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";
import { FaQuestion } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

import './header.css';
import './headerMobile.css';

const Header = () => {
    const [show, setShow] = useState(false);

    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('dark-mode');
        return savedMode === 'true';
    });

    const onToggleDarkMode = () => {
        setDarkMode((prevdarkMode) => {
            const newMode = !prevdarkMode;
            localStorage.setItem('dark-mode', newMode);
            return newMode;
        });
    };

    useEffect(() => {
        document.body.className = darkMode ? 'dark-mode' : 'light-mode';
    }, [darkMode]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <header data-theme={darkMode ? 'dark' : 'light'}>
                <nav>
                    <div className="logo-container">
                        <Link to={'/'}>
                            <img src={logo} alt="ustozweb" />
                            <span>UstozWeb</span>
                        </Link>
                    </div>
                    <div className="header-right-container">
                        <div className="header-btn">
                            {darkMode ? (
                                <CiCloudSun onClick={onToggleDarkMode} />

                            ) : (
                                <MdDarkMode onClick={onToggleDarkMode} />
                            )}
                            <Link><MdOutlineMessage /></Link>
                            {
                                show ? <IoClose onClick={handleClose} />
                                    : <HiMenuAlt3 className='menu-btn' onClick={handleShow} />
                            }
                        </div>
                        <div className="btns">
                            <button>Kirish</button>
                        </div>
                    </div>
                </nav>
            </header>
            {show ?
                <>
                    {/* Overlay layer */}
                    <div className={`menu-overlay ${show ? 'show' : ''}`} onClick={handleClose}>

                    </div>
                    {/* Offcanvas Menu */}
                    <div className='menu-left'>
                        <nav>
                            <ul>
                                <li><Link to={'/'} > <IoHome />Bosh sahifa</Link></li>
                                <li><Link to={'/course'} > <RiCodeSSlashLine />Barcha kurslar</Link></li>
                                <li><Link to={'/blog'} > <ImBlog />Blog</Link></li>
                                <li><Link to={'/issues'} ><DiCodeigniter />Masalalar to'plami</Link></li>
                                <li><Link to={'/projects'} > <FaProjectDiagram />Loyihalar</Link></li>
                                <li><Link to={'/contact'} > <RiContactsFill />Bog'lanish</Link></li>
                                <li><Link to={'/faq'} > <FaQuestion />FAQ</Link></li>
                            </ul>
                        </nav>
                        <footer>
                            <div className="menu-left-footer-message">
                                <ul>
                                    <li><Link target='_blank' to={'https://t.me/ustozweb'} ><FaTelegram /></Link></li>
                                    <li><Link target='_blank' to={' https://instagram.com/sariqdasturchi '} ><FaInstagram /></Link></li>
                                    <li><Link target='_blank' to={'https://youtube.com/@sariqdasturchi'} ><FaYoutube /></Link></li>
                                    <li><Link target='_blank' to={'https://www.linkedin.com/in/sukhrob-bozorov-45b83023b/'} ><FaLinkedin /></Link></li>
                                    <li><Link target='_blank' to={'https://github.com/Sariqdasturchi'} ><FaGithub /></Link></li>
                                </ul>
                            </div>
                            <div className="menu-left-footer">
                                <p> &copy; UstozWeb 2024</p>
                            </div>
                        </footer>
                    </div>
                </>
                : null
            }
        </>
    );
};

export default Header;
