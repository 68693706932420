import React from 'react';
// import icon
import Carousel from '../carousel/carousel';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
// css import 
import './main.css';
import './mainMobile.css';

const Main = () => {
    return (
        <>
        <Header />
            <div className='main-container'>
                {/* corusel */}
                <div className="main-corusel">
                    <Carousel />
                </div>

                <div className="main-title">
                    <div className='circle-title'>
                        <div className="circle-block">
                            <div className="circle"></div>
                            <div className="circle"></div>
                            <div className="circle"></div>
                        </div>
                    </div>
                    <div className="title-text">
                        <div>
                            <h1>Dasturlash sohasiga kerak bo'ladigan texnologiyalarni bizning "UstozWeb" platformamiz <br /> orqali mutloqa tekinga o'rganish imkonini qo'lga kiriting!</h1>
                            <p></p>
                        </div>
                        <div>
                            <h1>Biz sizga foydali Video darsliklar to'plamini undan tashqari platformadan chiqmasdan <br /> bemalol Kod muhiti bilan erkin ishlashni taqdim eta olamiz!</h1>
                        </div>
                        <div>
                            <h1>Mavzularga oid Masalalar to'plamini dars davomida yechib borib o'zingizni fikrlash <br /> darajangizni oshirib borishingiz mumkin. Bu uchun biz sizga masalar to'plamizni taqdim etammiz!</h1>
                        </div>
                    </div>
                </div>
                <div className="main-btn-login">
                    <button>Hoziroq boshlash</button>
                </div>
            </div>
            <Sidebar />
        </>
    );
}

export default Main;
