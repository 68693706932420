import React from 'react';

// css import
import './contactMe.css';
import './contactMeMobile.css';

import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
import { Link } from 'react-router-dom';

// message
import { FaTelegramPlane } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { FaGithub } from "react-icons/fa";


const ContactMe = () => {
    return (
        <>
            <Header />
            <div className='contactMe-container'>
                <div className="contactMe-block">
                    <div className="userMe-cont">
                        <div className="user-img"></div>
                        <div className="user-disc">
                            <h1>Suhrob Bozorov (Sariqdasturchi)</h1>
                            <p><u>UstozWeb</u> asoschisi <i>Frontend</i> dasturchi</p>
                        </div>
                    </div>
                    <div className="userMe-title">
                        <h1>Men haqimda</h1>
                        <br />
                        <p>
                            Men — frontend dasturchiman va veb-texnologiyalar, jumladan HTML, CSS, JavaScript, React va kerakli kutubxonalar bo‘yicha yetarli bilimga egaman. Hozirda <b>UstozWeb</b> startup loyiha asoschisiman va foydalanuvchilar uchun qulay va zamonaviy interfeyslar yaratish ustida ishlayman. O‘z ustimda ishlash va web-dasturlashning eng so‘nggi yangiliklaridan xabardor bo‘lishga doimiy intilaman. <br /> <br />
                            2021-yilda dasturlashga ilk qadamimni qo'yganman, IT-Park da olingan bilimlarim tufayli men ishda nafaqat texnik ko‘nikmalar, balki ijodiy yondashuvni ham qo‘llayman. Muammolarni hal qilishni yaxshi ko‘raman va jamoaviy muhitda ishlashda o‘zimni erkin his qilaman.
                            Dasturlashdan tashqari, bo‘sh vaqtimda <i>Kitob o'qish, Musiqa tinglash, Sayir qilish va h.k</i> bilan shug‘ullanishni yoqtiraman.
                        </p>
                        <div className="message-block">
                            <ul>
                                <Link to={'https://t.me/ustozweb'} target='_blank'><FaTelegramPlane /></Link>
                                <Link to={'https://instagram.com/sariqdasturchi '} target='_blank'><FaInstagram /></Link>
                                <Link to={'https://youtube.com/@sariqdasturchi'} target='_blank'><FaYoutube /></Link>
                                <Link to={'https://'} target='_blank'><IoLogoLinkedin /></Link>
                                <Link to={'https://github.com/Sariqdasturchi'} target='_blank'><FaGithub /></Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="contactMe-form">
                    <form>
                        <div>
                            <input type="text" required placeholder='Ism' />
                            <input type="email" required placeholder='Email' />
                        </div>
                        <div>
                            <textarea name="text" id="text" required placeholder='Xabar...'></textarea>
                        </div>
                        <div>
                            <button type='submit'>Yuborish</button>
                        </div>
                    </form>
                </div>
            </div>
            <Sidebar />
        </>
    );
}

export default ContactMe;
