import React from 'react';

// css import
import './errorPage.css';
import './errorPageMobile.css';

const ErrorPage = () => {
    return (
        <div className='errorPage-container'>
            <div className="not-found"></div>
        </div>
    );
}

export default ErrorPage;
