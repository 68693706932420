import React from "react";
import Slider from "react-slick";
import carousel from '../imgs/avatar1.jpg'
import carousel2 from '../imgs/web 001.jpg'
import carousel3 from '../imgs/carousel3.png'

import './carousel.css'

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <Slider {...settings}>
            <div>
                <img loading="layz" src={carousel} alt="" />
            </div>
            <div>
               <img loading="layz" src={carousel2} alt="" />
            </div>
            <div>
               <img loading="layz" src={carousel3} alt="" />
            </div>
        </Slider>
    );
}

export default Carousel;
