import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './App.css'
import './darkmode.css'

import Main from "../components/main/main";
import Course from "../components/course/course";
import JsProblemSolver from "../components/codeEditor/codeEditor";
import Issues from "../components/issues/issues";
import Projects from "../components/projects/projects";
import ContactMe from "../components/contactMe/contactMe";
import ErrorPage from "../components/errorPage/errorPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      errorElement: <ErrorPage />
    },
    {
      path: '/course',
      element: <Course />
    },
    {
      path: '/code-editor/:id',
      element: <JsProblemSolver />
    },
    {
      path: "/issues",
      element: <Issues />
    },
    {
      path: "/projects",
      element: <Projects />
    },
    {
      path: '/contact',
      element: <ContactMe />
    }
  ]);
  return (
    <>
      <section>
        <RouterProvider router={router} />
      </section>
    </>
  );
}

export default App;
