import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";

import './codeEditor.css';
import './codeEditorMobile.css';
import { useParams } from "react-router-dom";

const jsProblems = [
    {
        id: 1,
        question: "Yig'indi hisoblash: Berilgan sonlar ro'yxatining yig'indisini hisoblang.",
        description: "Sizga berilgan sonlar ro'yxatidan barcha elementlarning yig'indisini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 10",
        starterCode: `function sumArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 10 },
            { input: [5, 5, 5, 5], output: 20 },
            { input: [0, 0, 0, 0], output: 0 },
            { input: [-1, -2, -3, -4], output: -10 }
        ]
    },
    {
        id: 2,
        question: "Juft sonlarni toping: Berilgan ro'yxatda juft sonlarni aniqlang.",
        description: "Sizga sonlar ro'yxati berilgan. Ushbu ro'yxatdagi barcha juft sonlarni qaytaring.",
        example: "Input: [1, 2, 3, 4, 5] \nOutput: [2, 4]",
        starterCode: `function findEvenNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4, 5], output: [2, 4] },
            { input: [10, 15, 20, 25], output: [10, 20] },
            { input: [7, 13, 17], output: [] },
            { input: [8, 16, 32], output: [8, 16, 32] }
        ]
    },
    {
        id: 3,
        question: "Faktorialni hisoblang: Berilgan sonning faktorialini hisoblang.",
        description: "Berilgan n musbat butun soni uchun n! (faktorial) ni hisoblang.",
        example: "Input: 5 \nOutput: 120",
        starterCode: `function factorial(n) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 5, output: 120 },
            { input: 0, output: 1 },
            { input: 6, output: 720 },
            { input: 1, output: 1 }
        ]
    },
    {
        id: 4,
        question: "Palindrommi yoki yo'qmi: Berilgan so'z palindrom ekanligini tekshiring.",
        description: "So'z teskari o'qilganda ham xuddi o'sha so'z bo'lsa, palindrom deyiladi.",
        example: "Input: 'racecar' \nOutput: true",
        starterCode: `function isPalindrome(word) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'racecar', output: true },
            { input: 'hello', output: false },
            { input: 'madam', output: true },
            { input: 'abc', output: false }
        ]
    },
    {
        id: 5,
        question: "Fibonacci sonlari: Berilgan n ga ko'ra Fibonacci ketma-ketligini qaytaring.",
        description: "Berilgan n musbat butun soni uchun Fibonacci ketma-ketligining birinchi n elementini qaytaring.",
        example: "Input: 5 \nOutput: [0, 1, 1, 2, 3]",
        starterCode: `function fibonacci(n) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 5, output: [0, 1, 1, 2, 3] },
            { input: 7, output: [0, 1, 1, 2, 3, 5, 8] },
            { input: 1, output: [0] },
            { input: 3, output: [0, 1, 1] }
        ]
    },
    {
        id: 6,
        question: "Matnni yuqori harfga o'tkazing: Berilgan matndagi barcha harflarni yuqori harfga o'tkazing.",
        description: "Berilgan matnning barcha harflarini katta harfga o'zgartiring va natijani qaytaring.",
        example: "Input: 'hello' \nOutput: 'HELLO'",
        starterCode: `function toUpperCase(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 'HELLO' },
            { input: 'javascript', output: 'JAVASCRIPT' },
            { input: 'OpenAI', output: 'OPENAI' }
        ]
    },
    {
        id: 7,
        question: "Teskari matn: Berilgan matnni teskari aylantiring.",
        description: "Berilgan matnning barcha harflarini teskari tartibda aylantirib qaytaring.",
        example: "Input: 'world' \nOutput: 'dlrow'",
        starterCode: `function reverseString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'world', output: 'dlrow' },
            { input: 'javascript', output: 'tpircsavaj' },
            { input: 'OpenAI', output: 'IAnepO' }
        ]
    },
    {
        id: 8,
        question: "Sonlarni tartiblash: Berilgan sonlar ro'yxatini o'sish tartibida tartiblash.",
        description: "Sizga sonlar ro'yxati berilgan. Ushbu ro'yxatni o'sish tartibida qaytaring.",
        example: "Input: [3, 1, 4, 1, 5] \nOutput: [1, 1, 3, 4, 5]",
        starterCode: `function sortArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4, 1, 5], output: [1, 1, 3, 4, 5] },
            { input: [10, 9, 8, 7], output: [7, 8, 9, 10] },
            { input: [5, 1, 3], output: [1, 3, 5] }
        ]
    },
    {
        id: 9,
        question: "Eng katta element: Berilgan ro'yxatdagi eng katta elementni toping.",
        description: "Sizga berilgan sonlar ro'yxatidagi eng katta sonni qaytaring.",
        example: "Input: [3, 1, 4, 1, 5] \nOutput: 5",
        starterCode: `function findMax(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4, 1, 5], output: 5 },
            { input: [10, 20, 30], output: 30 },
            { input: [0, -1, -10], output: 0 }
        ]
    },
    {
        id: 10,
        question: "Matnni so'zlarga ajratish: Berilgan matnni so'zlarga ajratib, ro'yxat ko'rinishida qaytaring.",
        description: "Matnni bo'sh joylar bo'yicha so'zlarga ajrating va ro'yxat shaklida qaytaring.",
        example: "Input: 'hello world' \nOutput: ['hello', 'world']",
        starterCode: `function splitString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: ['hello', 'world'] },
            { input: 'javascript is fun', output: ['javascript', 'is', 'fun'] },
            { input: 'OpenAI', output: ['OpenAI'] }
        ]
    },
    {
        id: 11,
        question: "Matn uzunligini hisoblang: Berilgan matnning uzunligini qaytaring.",
        description: "Matnning uzunligini hisoblab natijani qaytaring.",
        example: "Input: 'hello' \nOutput: 5",
        starterCode: `function getStringLength(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 5 },
            { input: 'javascript', output: 10 },
            { input: '', output: 0 }
        ]
    },
    {
        id: 12,
        question: "Raqamlarni matnga o'zgartiring: Berilgan raqamni matnga aylantiring.",
        description: "Raqamni matnga aylantirib qaytaring.",
        example: "Input: 123 \nOutput: '123'",
        starterCode: `function numberToString(num) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 123, output: '123' },
            { input: 0, output: '0' },
            { input: 999, output: '999' }
        ]
    },
    {
        id: 13,
        question: "Ikki raqamni ko'paytiring: Berilgan ikki raqamning ko'paytmasini qaytaring.",
        description: "Ikki sonni ko'paytirib, natijani qaytaring.",
        example: "Input: 3, 4 \nOutput: 12",
        starterCode: `function multiply(a, b) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 4], output: 12 },
            { input: [5, 6], output: 30 },
            { input: [0, 100], output: 0 }
        ]
    },
    {
        id: 14,
        question: "Bo'sh matnmi: Berilgan matn bo'sh ekanligini tekshiring.",
        description: "Agar matn bo'sh bo'lsa, true; aks holda false qaytaring.",
        example: "Input: '' \nOutput: true",
        starterCode: `function isEmptyString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: '', output: true },
            { input: 'hello', output: false },
            { input: ' ', output: false }
        ]
    },
    {
        id: 15,
        question: "Matnni bosh va oxiridagi bo'sh joylardan tozalang.",
        description: "Matnni boshidagi va oxiridagi bo'sh joylarni olib tashlab, tozalangan matnni qaytaring.",
        example: "Input: '  hello  ' \nOutput: 'hello'",
        starterCode: `function trimString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: '  hello  ', output: 'hello' },
            { input: '  javascript  ', output: 'javascript' },
            { input: 'OpenAI', output: 'OpenAI' }
        ]
    },
    {
        id: 16,
        question: "So'zlarni teskari aylantiring: Berilgan matndagi so'zlarning tartibini teskari aylantiring.",
        description: "Matnning so'zlarini teskari tartibda qaytaring.",
        example: "Input: 'hello world' \nOutput: 'world hello'",
        starterCode: `function reverseWords(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 'world hello' },
            { input: 'javascript is fun', output: 'fun is javascript' },
            { input: 'OpenAI', output: 'OpenAI' }
        ]
    },
    {
        id: 17,
        question: "To'liq kvadratmi yoki yo'qmi: Berilgan son to'liq kvadrat ekanligini aniqlang.",
        description: "Agar berilgan son to'liq kvadrat bo'lsa, true; aks holda false qaytaring.",
        example: "Input: 16 \nOutput: true",
        starterCode: `function isPerfectSquare(num) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 16, output: true },
            { input: 14, output: false },
            { input: 25, output: true }
        ]
    },
    {
        id: 18,
        question: "Sonlarni juft va toqga ajrating.",
        description: "Berilgan ro'yxatdagi sonlarni juft va toq sonlarga ajratib, natijalarni qaytaring.",
        example: "Input: [1, 2, 3, 4, 5] \nOutput: { even: [2, 4], odd: [1, 3, 5] }",
        starterCode: `function separateEvenOdd(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4, 5], output: { even: [2, 4], odd: [1, 3, 5] } },
            { input: [10, 15, 20, 25], output: { even: [10, 20], odd: [15, 25] } },
            { input: [7, 13, 17], output: { even: [], odd: [7, 13, 17] } }
        ]
    },
    {
        id: 19,
        question: "Sonlar o'rtachasi: Berilgan sonlar ro'yxatining o'rtachasini hisoblang.",
        description: "Sizga berilgan sonlar ro'yxatidan barcha elementlarning o'rtachasini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 2.5",
        starterCode: `function calculateAverage(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 2.5 },
            { input: [10, 20, 30, 40], output: 25 },
            { input: [5, 5, 5], output: 5 }
        ]
    },
    {
        id: 20,
        question: "Eng kichik element: Berilgan ro'yxatdagi eng kichik elementni toping.",
        description: "Sizga berilgan sonlar ro'yxatidagi eng kichik sonni qaytaring.",
        example: "Input: [3, 1, 4, 1, 5] \nOutput: 1",
        starterCode: `function findMin(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4, 1, 5], output: 1 },
            { input: [10, 20, 30], output: 10 },
            { input: [-1, 0, -10], output: -10 }
        ]
    },
    {
        id: 21,
        question: "Matnni bosh harfini katta qilish: Berilgan matnning har bir so'zini bosh harfini katta qiling.",
        description: "Berilgan matnning har bir so'zining bosh harfini katta qilib qaytaring.",
        example: "Input: 'hello world' \nOutput: 'Hello World'",
        starterCode: `function capitalizeWords(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 'Hello World' },
            { input: 'javascript is fun', output: 'Javascript Is Fun' },
            { input: 'openai', output: 'Openai' }
        ]
    },
    {
        id: 22,
        question: "Yuqori va pastki harflarni almashtirish: Berilgan matndagi harflarning holatini o'zgartiring.",
        description: "Har bir harfni yuqori harf bo'lsa pastki, pastki bo'lsa yuqori harfga aylantiring.",
        example: "Input: 'Hello' \nOutput: 'hELLO'",
        starterCode: `function swapCase(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'Hello', output: 'hELLO' },
            { input: 'JavaScript', output: 'jAVAsCRIPT' },
            { input: 'OpenAI', output: 'oPENai' }
        ]
    },
    {
        id: 23,
        question: "Unli harflarni sanash: Berilgan matnda nechta unli harf borligini aniqlang.",
        description: "Berilgan matndagi barcha unli harflarni sanab, ularning sonini qaytaring.",
        example: "Input: 'hello' \nOutput: 2",
        starterCode: `function countVowels(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 2 },
            { input: 'javascript', output: 3 },
            { input: 'OpenAI', output: 3 }
        ]
    },
    {
        id: 24,
        question: "Takrorlanadigan elementlarni toping: Berilgan ro'yxatda takrorlanuvchi elementlarni qaytaring.",
        description: "Berilgan ro'yxatdagi takrorlanadigan elementlarni qaytaring.",
        example: "Input: [1, 2, 3, 1, 2] \nOutput: [1, 2]",
        starterCode: `function findDuplicates(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 1, 2], output: [1, 2] },
            { input: [4, 5, 6, 6, 7], output: [6] },
            { input: [10, 20, 30], output: [] }
        ]
    },
    {
        id: 25,
        question: "Bo'sh emasmi: Berilgan ro'yxat bo'sh emas ekanligini tekshiring.",
        description: "Agar ro'yxat bo'sh bo'lmasa true, bo'sh bo'lsa false qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: true",
        starterCode: `function isNotEmpty(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: true },
            { input: [], output: false },
            { input: [0], output: true }
        ]
    },
    {
        id: 26,
        question: "Teskari Fibonacci: Berilgan n ga ko'ra teskari Fibonacci ketma-ketligini qaytaring.",
        description: "Berilgan n musbat butun soni uchun Fibonacci ketma-ketligini teskari qaytaring.",
        example: "Input: 5 \nOutput: [3, 2, 1, 1, 0]",
        starterCode: `function reverseFibonacci(n) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 5, output: [3, 2, 1, 1, 0] },
            { input: 7, output: [8, 5, 3, 2, 1, 1, 0] },
            { input: 1, output: [0] }
        ]
    },
    {
        id: 27,
        question: "Sonlar yig'indisi va ko'paytmasi: Berilgan sonlar ro'yxatining yig'indisi va ko'paytmasini qaytaring.",
        description: "Berilgan ro'yxatdagi sonlarning yig'indisi va ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: { sum: 6, product: 6 }",
        starterCode: `function sumAndProduct(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: { sum: 6, product: 6 } },
            { input: [4, 5], output: { sum: 9, product: 20 } },
            { input: [0, 1, 2], output: { sum: 3, product: 0 } }
        ]
    },
    {
        id: 28,
        question: "Eng uzun so'z: Berilgan matnda eng uzun so'zni toping.",
        description: "Matndagi eng uzun so'zni qaytaring.",
        example: "Input: 'hello world' \nOutput: 'hello'",
        starterCode: `function findLongestWord(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 'hello' },
            { input: 'javascript is fun', output: 'javascript' },
            { input: 'OpenAI', output: 'OpenAI' }
        ]
    },
    {
        id: 29,
        question: "Raqamlarni toping: Berilgan matndagi raqamlarni aniqlang.",
        description: "Matndagi barcha raqamlarni ajratib, ro'yxat qilib qaytaring.",
        example: "Input: 'a1b2c3' \nOutput: [1, 2, 3]",
        starterCode: `function findNumbers(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'a1b2c3', output: [1, 2, 3] },
            { input: '123abc', output: [1, 2, 3] },
            { input: 'no numbers', output: [] }
        ]
    },
    {
        id: 30,
        question: "Sonlarni faqat musbat qiling: Berilgan ro'yxatdagi sonlarni faqat musbat qiymatlarda qaytaring.",
        description: "Ro'yxatdagi barcha sonlarni musbat qilib, qaytaring.",
        example: "Input: [-1, 2, -3, 4] \nOutput: [1, 2, 3, 4]",
        starterCode: `function makeAllPositive(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [-1, 2, -3, 4], output: [1, 2, 3, 4] },
            { input: [-10, 0, 10], output: [10, 0, 10] },
            { input: [1, 2, 3], output: [1, 2, 3] }
        ]
    },
    {
        id: 31,
        question: "Bo'sh joylarni olib tashlash: Berilgan matndan barcha bo'sh joylarni olib tashlang.",
        description: "Matndagi barcha bo'sh joylarni olib tashlang.",
        example: "Input: 'hello world' \nOutput: 'helloworld'",
        starterCode: `function removeSpaces(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 'helloworld' },
            { input: ' a b c ', output: 'abc' },
            { input: 'no spaces', output: 'nospaces' }
        ]
    },
    {
        id: 32,
        question: "Sonlarni alifbo tartibiga keltiring: Berilgan matndagi sonlarni alifbo tartibida qaytaring.",
        description: "Matndagi raqamlarni alifbo tartibida joylashtirib qaytaring.",
        example: "Input: [3, 1, 2] \nOutput: [1, 2, 3]",
        starterCode: `function sortNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 2], output: [1, 2, 3] },
            { input: [5, 4, 7, 1], output: [1, 4, 5, 7] },
            { input: [9, 8, 7], output: [7, 8, 9] }
        ]
    },
    {
        id: 33,
        question: "Faktoriyal toping: Berilgan sonning faktoriyalini hisoblang.",
        description: "Sonning faktoriyalini hisoblab qaytaring.",
        example: "Input: 5 \nOutput: 120",
        starterCode: `function factorial(n) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 5, output: 120 },
            { input: 3, output: 6 },
            { input: 0, output: 1 }
        ]
    },
    {
        id: 34,
        question: "Matnni aylantiring: Berilgan matnni aylantirib qaytaring.",
        description: "Matnni teskari tartibda qaytaring.",
        example: "Input: 'hello' \nOutput: 'olleh'",
        starterCode: `function reverseString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 'olleh' },
            { input: 'OpenAI', output: 'IAnepO' },
            { input: 'javascript', output: 'tpircsavaj' }
        ]
    },
    {
        id: 35,
        question: "Matn uzunligini aniqlang: Berilgan matn uzunligini qaytaring.",
        description: "Matnning uzunligini qaytaring.",
        example: "Input: 'hello' \nOutput: 5",
        starterCode: `function getStringLength(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 5 },
            { input: 'javascript', output: 10 },
            { input: '', output: 0 }
        ]
    },
    {
        id: 36,
        question: "Eng katta sonni toping: Berilgan sonlar ro'yxatidagi eng katta sonni qaytaring.",
        description: "Ro'yxatdagi eng katta sonni toping.",
        example: "Input: [3, 6, 9, 2] \nOutput: 9",
        starterCode: `function findMax(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 6, 9, 2], output: 9 },
            { input: [1, 2, 3], output: 3 },
            { input: [-1, -5, -3], output: -1 }
        ]
    },
    {
        id: 37,
        question: "Musbat sonlar yig'indisi: Berilgan ro'yxatdagi musbat sonlarning yig'indisini hisoblang.",
        description: "Musbat sonlar yig'indisini qaytaring.",
        example: "Input: [1, -2, 3, 4] \nOutput: 8",
        starterCode: `function sumPositiveNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, -2, 3, 4], output: 8 },
            { input: [0, -10, 5], output: 5 },
            { input: [-1, -2, -3], output: 0 }
        ]
    },
    {
        id: 38,
        question: "So'zlar sonini hisoblang: Berilgan matnda nechta so'z borligini aniqlang.",
        description: "Matndagi so'zlar sonini qaytaring.",
        example: "Input: 'hello world' \nOutput: 2",
        starterCode: `function countWords(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 2 },
            { input: 'javascript is fun', output: 3 },
            { input: '', output: 0 }
        ]
    },
    {
        id: 39,
        question: "Sonlar ro'yxatini teskari tartibda qaytaring.",
        description: "Berilgan sonlar ro'yxatini teskari tartibda qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [3, 2, 1]",
        starterCode: `function reverseArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [3, 2, 1] },
            { input: [10, 20, 30], output: [30, 20, 10] },
            { input: [5], output: [5] }
        ]
    },
    {
        id: 40,
        question: "Bo'shmi yoki yo'qmi: Berilgan ro'yxat bo'sh ekanligini tekshiring.",
        description: "Agar ro'yxat bo'sh bo'lsa true; aks holda false qaytaring.",
        example: "Input: [] \nOutput: true",
        starterCode: `function isEmpty(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [], output: true },
            { input: [1, 2, 3], output: false },
            { input: [0], output: false }
        ]
    },
    {
        id: 41,
        question: "Teskari elementlar: Berilgan ro'yxatdagi elementlarni teskari tartibda joylashtiring.",
        description: "Ro'yxatdagi elementlarni teskari tartibda qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [3, 2, 1]",
        starterCode: `function reverseArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [3, 2, 1] },
            { input: [10, 20, 30], output: [30, 20, 10] },
            { input: [5], output: [5] }
        ]
    },
    {
        id: 42,
        question: "O'zaro takrorlanuvchi raqamlarni aniqlash: Berilgan raqamlar ro'yxatida takrorlanuvchi raqamlarni toping.",
        description: "Ro'yxatdagi takrorlanadigan raqamlarni qaytaring.",
        example: "Input: [1, 2, 3, 2, 1] \nOutput: [1, 2]",
        starterCode: `function findRepeatedNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 2, 1], output: [1, 2] },
            { input: [4, 5, 6, 6, 7], output: [6] },
            { input: [10, 20, 30], output: [] }
        ]
    },
    {
        id: 43,
        question: "Matndagi unli harflarni hisoblash: Berilgan matndagi unli harflarni sanang.",
        description: "Matndagi unli harflarning sonini aniqlang.",
        example: "Input: 'hello' \nOutput: 2",
        starterCode: `function countVowels(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 2 },
            { input: 'javascript', output: 3 },
            { input: 'openai', output: 3 }
        ]
    },
    {
        id: 44,
        question: "Bo'sh joylarni olib tashlash: Berilgan matndan barcha bo'sh joylarni olib tashlang.",
        description: "Matndagi bo'sh joylarni olib tashlang.",
        example: "Input: 'hello world' \nOutput: 'helloworld'",
        starterCode: `function removeSpaces(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 'helloworld' },
            { input: ' a b c ', output: 'abc' },
            { input: 'no spaces', output: 'nospaces' }
        ]
    },
    {
        id: 45,
        question: "Matndagi raqamlarni ajratish: Berilgan matndagi raqamlarni ajratib oling.",
        description: "Matndagi raqamlarni ajratib, ro'yxat qilib qaytaring.",
        example: "Input: 'abc123def456' \nOutput: [1, 2, 3, 4, 5, 6]",
        starterCode: `function extractNumbers(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'abc123def456', output: [1, 2, 3, 4, 5, 6] },
            { input: 'no numbers', output: [] },
            { input: '5 apples and 6 oranges', output: [5, 6] }
        ]
    },
    {
        id: 46,
        question: "Takrorlanadigan harflarni toping: Berilgan matnda takrorlanadigan harflarni aniqlang.",
        description: "Matndagi takrorlanadigan harflarni qaytaring.",
        example: "Input: 'hello' \nOutput: ['h', 'e', 'l', 'o']",
        starterCode: `function findDuplicateLetters(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: ['h', 'e', 'l', 'o'] },
            { input: 'javascript', output: ['j', 'a', 'v', 's', 'c', 'r', 'i', 'p', 't'] },
            { input: 'abcd', output: [] }
        ]
    },
    {
        id: 47,
        question: "Musbat sonlar yig'indisi: Berilgan ro'yxatdagi musbat sonlarning yig'indisini hisoblang.",
        description: "Musbat sonlar yig'indisini qaytaring.",
        example: "Input: [1, -2, 3, 4] \nOutput: 8",
        starterCode: `function sumPositive(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, -2, 3, 4], output: 8 },
            { input: [-1, -2, -3], output: 0 },
            { input: [5, 10, -5], output: 15 }
        ]
    },
    {
        id: 48,
        question: "Sonlar bo'yicha eng katta va eng kichik toping: Berilgan ro'yxatdagi eng katta va eng kichik sonni aniqlang.",
        description: "Eng katta va eng kichik sonlarni qaytaring.",
        example: "Input: [3, 1, 4, 1, 5] \nOutput: { min: 1, max: 5 }",
        starterCode: `function findMinMax(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4, 1, 5], output: { min: 1, max: 5 } },
            { input: [0, -10, 10], output: { min: -10, max: 10 } },
            { input: [1], output: { min: 1, max: 1 } }
        ]
    },
    {
        id: 49,
        question: "Sonlar ro'yxatini o'zgartiring: Har bir sonni ikki baravariga oshiring.",
        description: "Berilgan ro'yxatdagi har bir sonni ikki baravariga oshirib qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [2, 4, 6]",
        starterCode: `function doubleNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [2, 4, 6] },
            { input: [4, 5], output: [8, 10] },
            { input: [-1, -2], output: [-2, -4] }
        ]
    },
    {
        id: 50,
        question: "Matndagi so'zlar sonini hisoblang: Berilgan matndagi so'zlar sonini aniqlang.",
        description: "Matndagi so'zlar sonini qaytaring.",
        example: "Input: 'hello world' \nOutput: 2",
        starterCode: `function countWords(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 2 },
            { input: 'javascript is fun', output: 3 },
            { input: '', output: 0 }
        ]
    },
    {
        id: 51,
        question: "Har bir so'zning uzunligini hisoblang: Berilgan matndagi har bir so'zning uzunligini aniqlang.",
        description: "Har bir so'zning uzunligini ro'yxat qilib qaytaring.",
        example: "Input: 'hello world' \nOutput: [5, 5]",
        starterCode: `function getWordLengths(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: [5, 5] },
            { input: 'javascript is awesome', output: [10, 2, 7] },
            { input: 'OpenAI', output: [6] }
        ]
    },
    {
        id: 52,
        question: "Ikkita ro'yxatni birlashtirish: Berilgan ikkita ro'yxatni birlashtiring.",
        description: "Ikkita ro'yxatni birlashtirib qaytaring.",
        example: "Input: [1, 2], [3, 4] \nOutput: [1, 2, 3, 4]",
        starterCode: `function mergeArrays(arr1, arr2) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [[1, 2], [3, 4]], output: [1, 2, 3, 4] },
            { input: [[1], [2]], output: [1, 2] },
            { input: [[], [3, 4]], output: [3, 4] }
        ]
    },
    {
        id: 53,
        question: "Berilgan ro'yxatdagi elementlarni takrorlang: Berilgan ro'yxatni takrorlanuvchi elementlar bilan qaytaring.",
        description: "Elementlar takrorlanadigan bo'lsa, ro'yxatni qaytaring.",
        example: "Input: [1, 2, 2] \nOutput: [1, 2, 2]",
        starterCode: `function repeatElements(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 2], output: [1, 2, 2] },
            { input: [1, 1, 1], output: [1, 1, 1] },
            { input: [1, 2, 3], output: [1, 2, 3] }
        ]
    },
    {
        id: 54,
        question: "Elementlar ro'yxatini tozalang: Berilgan ro'yxatda takrorlanadigan elementlarni olib tashlang.",
        description: "Ro'yxatdagi takrorlanadigan elementlarni olib tashlab, yangi ro'yxat qaytaring.",
        example: "Input: [1, 2, 2, 3] \nOutput: [1, 2, 3]",
        starterCode: `function removeDuplicates(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 2, 3], output: [1, 2, 3] },
            { input: [1, 1, 1], output: [1] },
            { input: [3, 2, 1], output: [3, 2, 1] }
        ]
    },
    {
        id: 55,
        question: "Raqqasni aniqlang: Berilgan ro'yxatda eng ko'p takrorlanadigan elementni aniqlang.",
        description: "Ro'yxatda eng ko'p takrorlanadigan elementni qaytaring.",
        example: "Input: [1, 2, 2, 3, 3, 3] \nOutput: 3",
        starterCode: `function findMode(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 2, 3, 3, 3], output: 3 },
            { input: [4, 4, 4, 5, 5], output: 4 },
            { input: [1, 2, 3], output: 1 } // 1, 2, 3 takrorlanmaydi, 1-dan kichik qiymat
        ]
    },
    {
        id: 56,
        question: "Berilgan ro'yxatdagi juft sonlarni aniqlang: Ro'yxatdagi juft sonlarni ajratib oling.",
        description: "Juft sonlarni qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: [2, 4]",
        starterCode: `function findEvenNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: [2, 4] },
            { input: [5, 7, 9], output: [] },
            { input: [0, -2, -4], output: [0, -2, -4] }
        ]
    },
    {
        id: 57,
        question: "Berilgan ro'yxatdagi toq sonlarni aniqlang: Ro'yxatdagi toq sonlarni ajratib oling.",
        description: "Toq sonlarni qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: [1, 3]",
        starterCode: `function findOddNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: [1, 3] },
            { input: [2, 4, 6], output: [] },
            { input: [1, 3, 5], output: [1, 3, 5] }
        ]
    },
    {
        id: 58,
        question: "Berilgan raqamlar orasidagi eng kichik sonni toping: Raqamlar ichidan eng kichik sonni aniqlang.",
        description: "Berilgan raqamlar ichidan eng kichik sonni qaytaring.",
        example: "Input: [3, 1, 4] \nOutput: 1",
        starterCode: `function findSmallest(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4], output: 1 },
            { input: [10, 20, 5], output: 5 },
            { input: [0, -10, -5], output: -10 }
        ]
    },
    {
        id: 59,
        question: "Berilgan raqamlar orasidagi eng katta sonni toping: Raqamlar ichidan eng katta sonni aniqlang.",
        description: "Berilgan raqamlar ichidan eng katta sonni qaytaring.",
        example: "Input: [3, 1, 4] \nOutput: 4",
        starterCode: `function findLargest(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 1, 4], output: 4 },
            { input: [10, 20, 30], output: 30 },
            { input: [0, -10, -5], output: 0 }
        ]
    },
    {
        id: 60,
        question: "Ro'yxatdagi barcha elementlarni ko'paytiring: Berilgan ro'yxatdagi barcha elementlarni ko'paytiring.",
        description: "Ro'yxatdagi barcha elementlarning ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function multiplyArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [4, 5], output: 20 },
            { input: [2, 3, 0], output: 0 }
        ]
    },
    {
        id: 61,
        question: "Ro'yxatdagi har bir elementni kvadratlang: Berilgan ro'yxatdagi har bir elementni kvadrat qilib qaytaring.",
        description: "Ro'yxatdagi har bir elementning kvadratini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [1, 4, 9]",
        starterCode: `function squareNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [1, 4, 9] },
            { input: [4, 5], output: [16, 25] },
            { input: [0, -2], output: [0, 4] }
        ]
    },
    {
        id: 62,
        question: "Matndagi harflarni ajratish: Berilgan matndagi harflarni alohida ro'yxatga joylashtiring.",
        description: "Matndagi harflarni ro'yxat qilib qaytaring.",
        example: "Input: 'abc' \nOutput: ['a', 'b', 'c']",
        starterCode: `function splitString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'abc', output: ['a', 'b', 'c'] },
            { input: 'hello', output: ['h', 'e', 'l', 'l', 'o'] },
            { input: '', output: [] }
        ]
    },
    {
        id: 63,
        question: "Matnni kichik harflarga aylantirish: Berilgan matndagi barcha harflarni kichik harflarga aylantiring.",
        description: "Matndagi barcha harflarni kichik harflarga aylantiring.",
        example: "Input: 'Hello' \nOutput: 'hello'",
        starterCode: `function toLowerCase(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'Hello', output: 'hello' },
            { input: 'JaVaScRiPt', output: 'javascript' },
            { input: '', output: '' }
        ]
    },
    {
        id: 64,
        question: "Matnni katta harflarga aylantirish: Berilgan matndagi barcha harflarni katta harflarga aylantiring.",
        description: "Matndagi barcha harflarni katta harflarga aylantiring.",
        example: "Input: 'hello' \nOutput: 'HELLO'",
        starterCode: `function toUpperCase(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 'HELLO' },
            { input: 'javascript', output: 'JAVASCRIPT' },
            { input: '', output: '' }
        ]
    },
    {
        id: 65,
        question: "Berilgan matnni teskari aylantirish: Berilgan matnni teskari tartibda qaytaring.",
        description: "Berilgan matnni teskari tartibda qaytaring.",
        example: "Input: 'hello' \nOutput: 'olleh'",
        starterCode: `function reverseString(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 'olleh' },
            { input: 'JavaScript', output: 'tpircSavaJ' },
            { input: '', output: '' }
        ]
    },
    {
        id: 66,
        question: "Bo'sh joylar bilan ajratish: Berilgan matndagi so'zlarni bo'sh joylar bilan ajrating.",
        description: "Matndagi so'zlarni ajratib qaytaring.",
        example: "Input: 'hello world' \nOutput: ['hello', 'world']",
        starterCode: `function splitBySpaces(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: ['hello', 'world'] },
            { input: 'java script', output: ['java', 'script'] },
            { input: '', output: [] }
        ]
    },
    {
        id: 67,
        question: "Yilni aniqlang: Berilgan yilning kabisar yoki oddiy yil ekanligini aniqlang.",
        description: "Berilgan yil kabisar yilmi yoki oddiy yilmi.",
        example: "Input: 2020 \nOutput: 'Kabisar'",
        starterCode: `function checkLeapYear(year) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 2020, output: 'Kabisar' },
            { input: 2021, output: 'Oddiy' },
            { input: 1900, output: 'Oddiy' }
        ]
    },
    {
        id: 68,
        question: "To'liq sonni aniqlash: Berilgan son to'liq sonmi yoki emas.",
        description: "Berilgan son to'liq sonmi aniqlang.",
        example: "Input: 5.5 \nOutput: 'Yolg'on'",
        starterCode: `function isInteger(num) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 5.5, output: "Yolg'on" },
            { input: 5, output: "To'liq" },
            { input: -3, output: "To'liq" }
        ]
    },
    {
        id: 69,
        question: "Bo'sh joylarni to'g'ri aniqlash: Berilgan matndagi bo'sh joylarning sonini aniqlang.",
        description: "Matndagi bo'sh joylarning sonini aniqlang.",
        example: "Input: 'hello world' \nOutput: 1",
        starterCode: `function countSpaces(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello world', output: 1 },
            { input: '   ', output: 3 },
            { input: 'no spaces', output: 0 }
        ]
    },
    {
        id: 70,
        question: "Berilgan matndagi belgilarni hisoblash: Berilgan matndagi har bir belgi qanchalik ko'p takrorlanishini aniqlang.",
        description: "Matndagi har bir belgi qanchalik takrorlanishini hisoblang.",
        example: "Input: 'hello' \nOutput: { h: 1, e: 1, l: 2, o: 1 }",
        starterCode: `function countCharacters(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: { h: 1, e: 1, l: 2, o: 1 } },
            { input: 'aabbcc', output: { a: 2, b: 2, c: 2 } },
            { input: 'abc', output: { a: 1, b: 1, c: 1 } }
        ]
    },
    {
        id: 71,
        question: "Soni sonlardan iborat bo'lgan raqamlar ro'yxatini aniqlash: Berilgan raqamlar ro'yxatidagi barcha sonlarni ajratib oling.",
        description: "Sonlarni ajratib qaytaring.",
        example: "Input: [1, 'two', 3] \nOutput: [1, 3]",
        starterCode: `function extractNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 'two', 3], output: [1, 3] },
            { input: ['one', 'two'], output: [] },
            { input: [0, 1, 'zero'], output: [0, 1] }
        ]
    },
    {
        id: 72,
        question: "Berilgan raqamlar ro'yxatini kamaytirish: Berilgan raqamlar ro'yxatini kamaytirib qaytaring.",
        description: "Ro'yxatdagi har bir sonni 1 ga kamaytirib qaytaring.",
        example: "Input: [3, 4, 5] \nOutput: [2, 3, 4]",
        starterCode: `function decreaseNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [3, 4, 5], output: [2, 3, 4] },
            { input: [1, 2, 3], output: [0, 1, 2] },
            { input: [0], output: [-1] }
        ]
    },
    {
        id: 73,
        question: "Berilgan sonning faktorlarini aniqlash: Berilgan sonning barcha faktorlarini qaytaring.",
        description: "Berilgan sonning barcha faktorlarini qaytaring.",
        example: "Input: 12 \nOutput: [1, 2, 3, 4, 6, 12]",
        starterCode: `function findFactors(num) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 12, output: [1, 2, 3, 4, 6, 12] },
            { input: 5, output: [1, 5] },
            { input: 10, output: [1, 2, 5, 10] }
        ]
    },
    {
        id: 74,
        question: "Yilni ikki raqamda aniqlash: Berilgan yilni ikki raqamga qisqartiring.",
        description: "Yilni ikki raqamda qaytaring.",
        example: "Input: 2024 \nOutput: 24",
        starterCode: `function getLastTwoDigits(year) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 2024, output: 24 },
            { input: 1999, output: 99 },
            { input: 2100, output: 0 },
        ]
    },
    {
        id: 75,
        question: "Sonlar ro'yxatini o'zgartirish: Berilgan sonlar ro'yxatidagi har bir sonni 10 ga oshiring.",
        description: "Ro'yxatdagi har bir sonni 10 ga oshirib qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [11, 12, 13]",
        starterCode: `function increaseNumbersByTen(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [11, 12, 13] },
            { input: [0], output: [10] },
            { input: [-1], output: [9] }
        ]
    },
    {
        id: 76,
        question: "Berilgan ro'yxatdagi har bir sonni kvadratlang: Ro'yxatdagi har bir sonning kvadratini qaytaring.",
        description: "Ro'yxatdagi har bir sonni kvadrat qilib qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [1, 4, 9]",
        starterCode: `function squareNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [1, 4, 9] },
            { input: [0], output: [0] },
            { input: [-1, 1], output: [1, 1] }
        ]
    },
    {
        id: 77,
        question: "Ro'yxatni teskari aylantiring: Berilgan ro'yxatni teskari tartibda qaytaring.",
        description: "Ro'yxatni teskari tartibda qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [3, 2, 1]",
        starterCode: `function reverseArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [3, 2, 1] },
            { input: [5, 4, 3], output: [3, 4, 5] },
            { input: [], output: [] }
        ]
    },
    {
        id: 78,
        question: "Berilgan ro'yxatdagi ixtiyoriy raqamlar sonini hisoblang: Berilgan ro'yxatdagi ixtiyoriy raqamlar sonini aniqlang.",
        description: "Ixtiyoriy raqamlarni hisoblang.",
        example: "Input: [1, 'a', 2, 'b'] \nOutput: 2",
        starterCode: `function countAnyNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 'a', 2, 'b'], output: 2 },
            { input: ['a', 'b'], output: 0 },
            { input: [1, 2, 3], output: 3 }
        ]
    },
    {
        id: 79,
        question: "Berilgan ro'yxatdagi sonlarni juft raqamlar bilan ajrating: Berilgan ro'yxatdagi sonlarni juft raqamlar bilan ajratib oling.",
        description: "Juft raqamlarni qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: [2, 4]",
        starterCode: `function separateEvenNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: [2, 4] },
            { input: [5, 7, 9], output: [] },
            { input: [0, -2, -4], output: [0, -2, -4] }
        ]
    },
    {
        id: 80,
        question: "Berilgan ro'yxatdagi sonlarni toq raqamlar bilan ajrating: Berilgan ro'yxatdagi sonlarni toq raqamlar bilan ajratib oling.",
        description: "Toq raqamlarni qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: [1, 3]",
        starterCode: `function separateOddNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: [1, 3] },
            { input: [2, 4, 6], output: [] },
            { input: [1, 3, 5], output: [1, 3, 5] }
        ]
    },
    {
        id: 81,
        question: "Berilgan ro'yxatdagi raqamlarning o'rtacha qiymatini aniqlang: Berilgan ro'yxatdagi raqamlarning o'rtacha qiymatini hisoblang.",
        description: "O'rtacha qiymatini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 2.5",
        starterCode: `function calculateAverage(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 2.5 },
            { input: [5, 10, 15], output: 10 },
            { input: [], output: 0 }
        ]
    },
    {
        id: 82,
        question: "Raqamlar ro'yxatini juft raqamlar bilan ajratib oling: Berilgan ro'yxatdagi raqamlarni juft va toq raqamlar bilan ajratib oling.",
        description: "Juft va toq raqamlarni qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: { even: [2, 4], odd: [1, 3] }",
        starterCode: `function separateEvenAndOdd(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: { even: [2, 4], odd: [1, 3] } },
            { input: [2, 4, 6], output: { even: [2, 4, 6], odd: [] } },
            { input: [1, 3, 5], output: { even: [], odd: [1, 3, 5] } }
        ]
    },
    {
        id: 83,
        question: "Berilgan ro'yxatdagi sonlarning yig'indisini aniqlang: Berilgan ro'yxatdagi raqamlarning yig'indisini hisoblang.",
        description: "Yig'indisini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function sumArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0, 0, 0], output: 0 },
            { input: [-1, 1], output: 0 }
        ]
    },
    {
        id: 84,
        question: "Berilgan ro'yxatdagi har bir raqamni 3 ga oshiring: Berilgan ro'yxatdagi har bir raqamni 3 ga oshirib qaytaring.",
        description: "Ro'yxatdagi har bir raqamni 3 ga oshirib qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [4, 5, 6]",
        starterCode: `function increaseNumbersByThree(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [4, 5, 6] },
            { input: [-1, 0], output: [2, 3] },
            { input: [0], output: [3] }
        ]
    },
    {
        id: 85,
        question: "Matndagi harflarni katta harfga aylantirish: Berilgan matndagi harflarni katta harfga aylantiring.",
        description: "Harflarni katta harfga aylantiring.",
        example: "Input: 'hello' \nOutput: 'HELLO'",
        starterCode: `function toUpperCase(str) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: 'hello', output: 'HELLO' },
            { input: 'java', output: 'JAVA' },
            { input: '', output: '' }
        ]
    },
    {
        id: 86,
        question: "Berilgan ro'yxatdagi juft raqamlar sonini hisoblang: Berilgan ro'yxatdagi juft raqamlar sonini aniqlang.",
        description: "Juft raqamlar sonini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 2",
        starterCode: `function countEvenNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 2 },
            { input: [1, 3, 5], output: 0 },
            { input: [2, 4, 6], output: 3 }
        ]
    },
    {
        id: 87,
        question: "Berilgan ro'yxatdagi toq raqamlar sonini hisoblang: Berilgan ro'yxatdagi toq raqamlar sonini aniqlang.",
        description: "Toq raqamlar sonini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 2",
        starterCode: `function countOddNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 2 },
            { input: [2, 4, 6], output: 0 },
            { input: [1, 3, 5], output: 3 }
        ]
    },
    {
        id: 88,
        question: "Berilgan raqamlarni teng ravishda to'plang: Berilgan ro'yxatdagi raqamlarni teng ravishda to'plang.",
        description: "Teng ravishda to'plang.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function equalSum(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [], output: 0 },
            { input: [-1, 1], output: 0 }
        ]
    },
    {
        id: 89,
        question: "Berilgan raqamlar ro'yxatidan eng katta raqamni aniqlang: Berilgan raqamlar ro'yxatidagi eng katta raqamni qaytaring.",
        description: "Eng katta raqamni aniqlang.",
        example: "Input: [1, 2, 3] \nOutput: 3",
        starterCode: `function findMax(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 3 },
            { input: [3, 2, 1], output: 3 },
            { input: [5, 5, 5], output: 5 }
        ]
    },
    {
        id: 90,
        question: "Berilgan raqamlar ro'yxatidan eng kichik raqamni aniqlang: Berilgan raqamlar ro'yxatidagi eng kichik raqamni qaytaring.",
        description: "Eng kichik raqamni aniqlang.",
        example: "Input: [1, 2, 3] \nOutput: 1",
        starterCode: `function findMin(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 1 },
            { input: [3, 2, 1], output: 1 },
            { input: [5, 5, 5], output: 5 }
        ]
    },
    {
        id: 91,
        question: "Berilgan raqamlar ro'yxatini tasodifiy tartibda qaytaring: Berilgan raqamlar ro'yxatini tasodifiy tartibda qaytaring.",
        description: "Raqamlarni tasodifiy tartibda qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: [3, 1, 2]",
        starterCode: `function shuffleArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: [3, 1, 2] }, // Note: Random output, may not match exactly
            { input: [1], output: [1] },
            { input: [], output: [] }
        ]
    },
    {
        id: 92,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning o'rtacha qiymatini aniqlang: Berilgan raqamlar ro'yxatidagi raqamlarning o'rtacha qiymatini hisoblang.",
        description: "O'rtacha qiymatini qaytaring.",
        example: "Input: [1, 2, 3, 4] \nOutput: 2.5",
        starterCode: `function calculateAverage(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3, 4], output: 2.5 },
            { input: [5, 10, 15], output: 10 },
            { input: [], output: 0 }
        ]
    },
    {
        id: 93,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning eng katta yig'indisini aniqlang: Berilgan raqamlar ro'yxatidagi raqamlarning eng katta yig'indisini aniqlang.",
        description: "Eng katta yig'indisini aniqlang.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function findMaxSum(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [], output: 0 },
            { input: [5, -5, 10], output: 10 }
        ]
    },
    {
        id: 94,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning eng kichik yig'indisini aniqlang: Berilgan raqamlar ro'yxatidagi raqamlarning eng kichik yig'indisini aniqlang.",
        description: "Eng kichik yig'indisini aniqlang.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function findMinSum(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [], output: 0 },
            { input: [-5, -10, -15], output: -30 }
        ]
    },
    {
        id: 95,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarni to'ldirish: Berilgan raqamlar ro'yxatidagi raqamlarni to'ldirish.",
        description: "Raqamlarni to'ldiring.",
        example: "Input: [1, 2] \nOutput: [1, 2, 3]",
        starterCode: `function fillNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2], output: [1, 2, 3] },
            { input: [2, 4], output: [2, 4, 5] },
            { input: [], output: [] }
        ]
    },
    {
        id: 96,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini aniqlang: Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini hisoblang.",
        description: "Ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function multiplyNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0], output: 0 },
            { input: [-1, 1], output: -1 }
        ]
    },
    {
        id: 97,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini hisoblang: Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini hisoblang.",
        description: "Ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function calculateProduct(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0], output: 0 },
            { input: [-1, 1], output: -1 }
        ]
    },
    {
        id: 98,
        question: "Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini aniqlang: Berilgan raqamlar ro'yxatidagi raqamlarning ko'paytmasini aniqlang.",
        description: "Ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function multiplyAllNumbers(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0], output: 0 },
            { input: [-1, 1], output: -1 }
        ]
    },
    {
        id: 99,
        question: "Berilgan raqamlar ro'yxatidagi sonlarning ko'paytmasini aniqlang: Berilgan raqamlar ro'yxatidagi sonlarning ko'paytmasini aniqlang.",
        description: "Ko'paytmasini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function multiplyArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0], output: 0 },
            { input: [-1, 1], output: -1 }
        ]
    },
    {
        id: 100,
        question: "Berilgan raqamlar ro'yxatidagi sonlarning yig'indisini aniqlang: Berilgan raqamlar ro'yxatidagi sonlarning yig'indisini aniqlang.",
        description: "Yig'indisini qaytaring.",
        example: "Input: [1, 2, 3] \nOutput: 6",
        starterCode: `function sumOfArray(arr) {
    // kodni shu yerda yozing
}`,
        testCases: [
            { input: [1, 2, 3], output: 6 },
            { input: [0], output: 0 },
            { input: [-1, 1], output: 0 }
        ]
    }
    
];


const JsProblemSolver = () => {
    const [output, setOutput] = useState("");
    const { id } = useParams(); // URLdan IDni olish
    const [selectedProblem, setSelectedProblem] = useState(null); // Dastlab `null` 

    // URLdan olingan ID bo'yicha masalani olish
    useEffect(() => {
        const problemId = Number(id); // ID ni son formatiga o'tkazish
        const foundProblem = jsProblems.find(p => p.id === problemId); // Masalani qidirish
        setSelectedProblem(foundProblem); // Topilgan masalani set qilish
    }, [id]); // ID o'zgarganda bu hook qayta ishlaydi

    const runCode = (code) => {
        try {
            const result = eval(code);
            setOutput(result !== undefined ? result : "No output");
        } catch (error) {
            setOutput(`Error: ${error.message}`);
        }
    };

    // Agar masala topilmasa
    if (!selectedProblem) {
        return <h2>Kurs topilmadi</h2>;
    }

    return ( 
        <>
            <Header />
            <div className="code-editor-container">
                <div className="code-editor-block">
                    <h2>{selectedProblem.title}</h2>
                    <p>{selectedProblem.description}</p>
                    <p><strong>Example:</strong> {selectedProblem.example}</p>

                    <Editor
                        height="500px"
                        language="javascript"
                        value={selectedProblem.starterCode}
                        theme="vs-dark"
                        onChange={(value) => runCode(value)}
                    />

                    <button
                        style={{
                            marginTop: "10px",
                            padding: "10px", 
                            backgroundColor: "#28a745",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                        }}
                        onClick={() => runCode(selectedProblem.starterCode)}
                    >
                        Kodni ishlatish
                    </button>

                    <div style={{ marginTop: "20px", backgroundColor: "none", padding: "10px", border: "1px solid #64748b", color: "black" }} className="natija">
                        <h3>Natija:</h3>
                        <pre>{output}</pre>
                    </div>
                </div>
            </div>
            <Sidebar />
        </>
    );
};

export default JsProblemSolver;
