import React from 'react';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';

import './issues.css';
import './issuesMobile.css'
import { Link } from 'react-router-dom';

const Issues = () => {
    const jsProblems = [
        { id: 1, question: "Yig'indi hisoblash: Berilgan sonlar ro'yxatining yig'indisini hisoblang." },
        { id: 2, question: "Maxsus sonlarni topish: Berilgan ro'yxatda juft sonlarni aniqlang." },
        { id: 3, question: "Faktorial hisoblash: Berilgan sonning faktorialini hisoblang." },
        { id: 4, question: "Palindrom: Berilgan matn palindrommi yoki yo'qmi?" },
        { id: 5, question: "Fibonacci sonlari: Berilgan n ga ko'ra Fibonacci sonlarini chiqarish." },
        { id: 6, question: "Yuqori harfga o'tkazish: Berilgan matndagi barcha harflarni yuqori harfga o'tkazing." },
        { id: 7, question: "Matnni aylantirish: Berilgan matnni teskari aylantiring." },
        { id: 8, question: "Sonlarni tartiblash: Berilgan sonlar ro'yxatini tartiblash." },
        { id: 9, question: "Maxsus element: Berilgan ro'yxatda eng katta elementni toping." },
        { id: 10, question: "Matnni taqsimlash: Berilgan matnni so'zlarga ajrating." },
        { id: 11, question: "Nishon ko'rinishi: Berilgan matnda nishon (target) so'zini qidirish." },
        { id: 12, question: "Tugma usuli: Tugma bilan matnni ko'rsatish." },
        { id: 13, question: "Ro'yxatdan element o'chirish: Berilgan ro'yxatdan belgilangan elementni o'chirish." },
        { id: 14, question: "Summa va o'rtacha: Berilgan ro'yxatning summasini va o'rtachasini hisoblang." },
        { id: 15, question: "Dublikatlarni aniqlash: Berilgan ro'yxatda dublikat elementlarni toping." },
        { id: 16, question: "Yil hisoblash: Berilgan yilning kabisa yili yoki yo'qligini aniqlang." },
        { id: 17, question: "O'zgaruvchi qiymatini o'zgartirish: Berilgan o'zgaruvchining qiymatini o'zgartiring." },
        { id: 18, question: "Berilgan matndagi sonlarni ajratib olish." },
        { id: 19, question: "Sonni ko'paytirish: Berilgan sonni ko'paytiruvchi bilan ko'paytiring." },
        { id: 20, question: "Teskari son: Berilgan sonni teskari ko'rinishga keltiring." },
        { id: 21, question: "Barchasi bir xilda: Berilgan ro'yxatning barcha elementlari bir xilmi?" },
        { id: 22, question: "Matnning uzunligini hisoblash." },
        { id: 23, question: "Teskari ro'yxat: Berilgan ro'yxatni teskari tartibda ko'rsatish." },
        { id: 24, question: "Raqamli o'sish: Berilgan raqamli ro'yxatni o'sish tartibida ko'rsatish." },
        { id: 25, question: "Raqamlar orasidagi eng kichik sonni topish." },
        { id: 26, question: "Berilgan ro'yxatdagi har bir raqamni ikki barobarga oshirish." },
        { id: 27, question: "Birinchi unli harfni aniqlash." },
        { id: 28, question: "Berilgan matnda kiritilgan harfni qidirish." },
        { id: 29, question: "Matndagi so'zlar sonini hisoblash." },
        { id: 30, question: "Yilga ko'ra to'g'ri sanani aniqlash." },
        { id: 31, question: "Berilgan raqamlar ro'yxatini o'zgaruvchi yordamida tartiblash." },
        { id: 32, question: "Har bir elementga 1 qo'shish." },
        { id: 33, question: "Raqamlar ro'yxatidan eng katta raqamni chiqarish." },
        { id: 34, question: "Juft sonlar va toq sonlar ro'yxatini ajratish." },
        { id: 35, question: "Har bir so'zni katta harf bilan boshlash." },
        { id: 36, question: "Berilgan matnda bir xil harflarning sonini hisoblash." },
        { id: 37, question: "Tugmachalar orqali elementlarni qo'shish." },
        { id: 38, question: "Ro'yxatga yangi element qo'shish." },
        { id: 39, question: "Sonlarning umumiy yig'indisini hisoblash." },
        { id: 40, question: "Palindrom so'zlar ro'yxatini ajratish." },
        { id: 41, question: "Yilning ikkinchi yarmi yoki birinchi yarmi ekanligini aniqlash." },
        { id: 42, question: "Har bir raqamni kvadratga oshirish." },
        { id: 43, question: "Ro'yxatdan toq sonlarni chiqarish." },
        { id: 44, question: "Matnni kichik harflarga o'tkazish." },
        { id: 45, question: "Teskari ro'yxatdagi eng katta elementni topish." },
        { id: 46, question: "Berilgan ro'yxatdagi eng kichik raqamni aniqlash." },
        { id: 47, question: "Yilga ko'ra to'liq sanani ko'rsatish." },
        { id: 48, question: "Berilgan ro'yxatda har bir raqamga 3 qo'shish." },
        { id: 49, question: "Berilgan ro'yxatni o'sish tartibida ko'rsatish." },
        { id: 50, question: "Raqamlar orasida eng katta va eng kichik sonlarni topish." },
        { id: 51, question: "Ro'yxatdagi har bir elementni 10 barobarga oshirish." },
        { id: 52, question: "Har bir so'zning uzunligini hisoblash." },
        { id: 53, question: "Har bir elementning kvadratini chiqarish." },
        { id: 54, question: "Berilgan matndagi bo'sh joylarni olib tashlash." },
        { id: 55, question: "Har bir sonni 3 ga bo'lish va qoldiqni aniqlash." },
        { id: 56, question: "Matnni aylantirish va harflarini yuqori harfga o'tkazish." },
        { id: 57, question: "Berilgan ro'yxatda 2 ga bo'linadigan raqamlarni topish." },
        { id: 58, question: "Sonlarni yig'ish va ularni chiqarish." },
        { id: 59, question: "Ro'yxatni to'ldirish va chiqarish." },
        { id: 60, question: "Dublikatlarni olib tashlash." },
        { id: 61, question: "Yilga ko'ra oylar ro'yxatini chiqarish." },
        { id: 62, question: "Berilgan matndagi harfning nechta marta takrorlanishini aniqlash." },
        { id: 63, question: "Raqamlar ro'yxatini teskari tartibda ko'rsatish." },
        { id: 64, question: "Berilgan raqamni 2 ga ko'paytirish." },
        { id: 65, question: "Berilgan ro'yxatdagi barcha sonlarning o'rtachasini hisoblash." },
        { id: 66, question: "Matnni harfma-harf ko'rsatish." },
        { id: 67, question: "Raqamlarni juft va toq sifatida ajratish." },
        { id: 68, question: "Berilgan matnni uchinchi harfidan boshlab chiqarish." },
        { id: 69, question: "Maxsus harfni o'zgartirish." },
        { id: 70, question: "Berilgan sonning juft yoki toqligini aniqlash." },
        { id: 71, question: "Matndagi harf va raqamlar sonini hisoblash." },
        { id: 72, question: "Sonlarni o'sish tartibida ko'rsatish." },
        { id: 73, question: "Matnni bo'sh joylar orqali ajratish." },
        { id: 74, question: "Raqamlar ro'yxatini 5 ga ko'paytirish." },
        { id: 75, question: "Matnning har bir so'zini kichik harflarga o'tkazish." },
        { id: 76, question: "Har bir raqamni 1 ga kamaytirish." },
        { id: 77, question: "Berilgan raqamdan 10 ni ayirish." },
        { id: 78, question: "Berilgan ro'yxatdagi elementlar sonini aniqlash." },
        { id: 79, question: "So'zlarni teskari tartibda chiqarish." },
        { id: 80, question: "Har bir so'zda birinchi unli harfni chiqarish." },
        { id: 81, question: "Berilgan ro'yxatda 5 ga bo'linadigan raqamlarni topish." },
        { id: 82, question: "Yilni aniqlash va to'g'ri sanani ko'rsatish." },
        { id: 83, question: "Berilgan matnda so'zlar sonini hisoblash." },
        { id: 84, question: "Berilgan sonni uch marta ko'paytirish." },
        { id: 85, question: "Berilgan ro'yxatni o'zgartirish." },
        { id: 86, question: "Maxsus so'zlarni ajratib olish." },
        { id: 87, question: "Berilgan matnda unli harflarni hisoblash." },
        { id: 88, question: "Raqamlar orasida eng katta raqamni topish." },
        { id: 89, question: "Berilgan ro'yxatda har bir elementning kvadratini hisoblash." },
        { id: 90, question: "Ro'yxatdagi raqamlarning yig'indisini chiqarish." },
        { id: 91, question: "Yilga ko'ra yillik taqvimni chiqarish." },
        { id: 92, question: "So'zlarning teskari tartibda chiqarilishi." },
        { id: 93, question: "Har bir so'zni teskari tartibda chiqarish." },
        { id: 94, question: "Berilgan raqamdan 1 ni ayirish." },
        { id: 95, question: "Raqamlarni juft va toq sifatida ajratish." },
        { id: 96, question: "Matnni to'liq bosh harflarga o'tkazish." },
        { id: 97, question: "Yilga ko'ra to'liq sanani aniqlash." },
        { id: 98, question: "Berilgan ro'yxatni bo'sh joylar orqali ajratish." },
        { id: 99, question: "Ro'yxatdagi eng kichik va eng katta raqamlarni topish." },
        { id: 100, question: "Raqamlarni yuqori harflar bilan chiqarish." }
    ];

    
    return (
        <>
            <Header />
            <div className='issues-container'>
                <div className="issues-list">
                    {
                        jsProblems.map((item) => (
                            <Link to={`/code-editor/${item.id}`} key={item.id} >
                                <div className="issues">
                                    <div className="issues-title">
                                        <h1>Masala: {item.id}</h1>
                                    </div>
                                    <div className="issues-desc">
                                        <p>{item.question}</p>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </div>
            <Sidebar />
        </>
    );
}

export default Issues;
