import React from 'react';
import { Link } from 'react-router-dom';
// react icons
import { IoHome } from "react-icons/io5";
import { DiCodeigniter } from "react-icons/di";
import { ImBlog } from "react-icons/im";
import { RiCodeSSlashLine } from "react-icons/ri";
import { FaProjectDiagram } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";
import { FaQuestion } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
// css import
import './sidebar.css';
import './sidebarMobile.css';


const Sidebar = () => {
    return (
        <div className='sidebar'>
            <nav>
                <ul>
                    <li><Link to={'/'} > <IoHome />Bosh sahifa</Link></li>
                    <li><Link to={'/course'} > <RiCodeSSlashLine />Barcha kurslar</Link></li>
                    <li><Link to={'/blog'} > <ImBlog />Blog</Link></li>
                    <li><Link to={'/issues'} ><DiCodeigniter />Masalalar to'plami</Link></li>
                    <li><Link to={'/projects'} > <FaProjectDiagram />Loyihalar</Link></li>
                    <li><Link to={'/contact'} > <RiContactsFill />Bog'lanish</Link></li>
                    <li><Link to={'/faq'} > <FaQuestion />FAQ</Link></li>
                </ul>
            </nav>
            <footer>
                <div className="sidebar-footer-message">
                    <ul>
                        <li><a ><FaTelegram /></a></li>
                        <li><a ><FaInstagram /></a></li>
                        <li><a ><FaYoutube /></a></li>
                        <li><a ><FaLinkedin /></a></li>
                        <li><a ><FaGithub /></a></li>
                    </ul>
                </div>
                <div className="sidebar-footer">
                    <p> &copy; UstozWeb 2024</p>
                </div>
            </footer>
        </div>
    );
}

export default Sidebar;
