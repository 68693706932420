import React from 'react';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';

import './course.css';
import './courseMobile.css';

const Course = () => {
    return (
        <>
            <Header />
            <div className="course-container">
                <div className="course-block">
                    <div className="course-title">
                        <h1>Barcha kurslarimiz</h1>
                    </div>
                    <div className="course-cards">
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                        <div className="course-card"></div>
                    </div>
                </div>
            </div>
            <Sidebar />
        </>
    );
}

export default Course;
