import React from 'react';

// css file import
import './projects.css';
import './projectsMobile.css'
// component
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
// imgs
import project1 from '../imgs/logoTextsiz.png'

const Projects = () => {
    const projectsDB = [
        {
            title: "Loyiha-1",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-2",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-3",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-4",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-5",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-6",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-7",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-8",
            link: 'https://t.me/ustozweb/',
            img: project1
        },
        {
            title: "Loyiha-9",
            link: 'https://t.me/ustozweb/',
            img: project1
        },

    ]
    return (
        <>
            {/* header */}
            <Header />

            <div className='projects-container'>
                <div className="project-title">
                    <h1>Barcha loyihalar</h1>
                </div>
                <div className="projects-block">
                    {
                        projectsDB.map((item) => (
                            <div className="projects-cards">
                                <div className="projects-card-title">
                                    <h1>{item.title}</h1>
                                </div>
                                <img loading='layz' src={item.img} alt="" />
                                <div className="projects-demo">
                                    <a href={item.link} target='blank'>
                                        Ko'rish
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            {/* Sidebar */}
            <Sidebar />
        </>
    );
}

export default Projects;
